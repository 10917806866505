import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactUsPage = () => (
  <Layout>
    <SEO title="Contact Us" />
    <div className="container">
      <h3>Contact Details</h3>
      <p>
        <i className="fas fa-phone"></i> <abbr title="Phone">P</abbr>: (+91) 84-240-645-75
      </p>
      <p>
        <i className="fas fa-phone"></i> <abbr title="Phone">P</abbr>: (+91) 97-020-996-90
      </p>
      <p>
        <i className="fas fa-envelope"></i> <abbr title="Email">E</abbr>: agneyaproducts@gmail.com
      </p>
      <ul className="list-unstyled list-inline list-social-icons">
        <li>
          <a href="https://www.facebook.com/Agneya-Products-483975465297202/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook-square fa-2x"></i>
          </a>
        </li>
        <li>
          <a href="https://www.facebook.com/pg/Agneya-Products-483975465297202/reviews/" target="_blank" rel="noopener noreferrer">
            Facebook Reviews
          </a>
        </li>
      </ul>
    </div>
  </Layout>
)

export default ContactUsPage
